<template>
<div class="margin-t-70">
    <Navbar/>
    <Contact/>
    <Footer/>
</div>
</template>

<script>
import Contact from "@/components/contact";
import Navbar from "@/components/navbar-stable";
import Footer from "@/components/footer";


export default {
    components : {
        Contact,
        Navbar,
        Footer
    }
}
</script>

<style>

</style>